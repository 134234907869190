<template>
  <f7-block-title medium>{{ $t('checkout.paymentRequests.items.title') }}</f7-block-title>
  <f7-card
      class="checkout-confirmation-items-section">
    <f7-card-content>
      <f7-block
        class="padding text-align-center"
        v-if="CheckoutStore.pullingPaymentRequests">
        <f7-preloader></f7-preloader>
      </f7-block>
      <f7-list
        class="checkout-payment-requests-items"
        media-list
        no-hairlines
        v-if="!CheckoutStore.pullingPaymentRequests && CheckoutStore.paymentRequests.length">
        <ul>
          <payment-request
            v-for="paymentRequest of CheckoutStore.paymentRequests"
            :key="paymentRequest.shopId"
            :shop-id="paymentRequest.shopId"
            :amount="paymentRequest.amount"
            :status="paymentRequest.status"
            :currency-code="paymentRequest.currencyCode"
            @click="onOpenPaymentMethods(paymentRequest)"></payment-request>
        </ul>
      </f7-list>
      <f7-block
        class="checkout-payment-requests-empty"
        v-if="!CheckoutStore.pullingPaymentRequests && !CheckoutStore.paymentRequests.length">
        {{ $t('checkout.confirmation.items.empty') }}
      </f7-block>
    </f7-card-content>
  </f7-card>
  <payment-methods
    :payment-request-id="selectedPaymentRequest._id"
    :shop-id="selectedPaymentRequest.shopId"
    :amount="selectedPaymentRequest.amount"
    :order-items="selectedPaymentRequest.orderItems"
    :currency-code="selectedPaymentRequest.currencyCode"
    v-if="selectedPaymentRequest"></payment-methods>
</template>

<script>
import {mapState} from 'vuex';
import PaymentRequest from '@/components/checkout/payment-requests/items/item';
import PaymentMethods from '@/components/checkout/payment-requests/payment-methods';
import {f7} from 'framework7-vue';

export default {
  components: {
    PaymentMethods,
    PaymentRequest
  },
  data() {
    return {
      selectedPaymentRequest: null,
    }
  },
  computed: {
    ...mapState(['CheckoutStore']),
  },
  methods: {
    onOpenPaymentMethods(paymentRequest) {
      if (paymentRequest.status === 'unpaid') {
        this.selectedPaymentRequest = paymentRequest;
        f7.sheet.open('.payment-methods-sheet');
      }
    }
  }
}
</script>
