import { VuelidatePlugin } from '@vuelidate/core';
import { helpers } from '@vuelidate/validators'


export function useVuelidate() {
  return VuelidatePlugin;
}

export function customValidator(f, m) {
  return helpers.withMessage(m, f);
}
