export default {
  app: {
    name: 'Cartel',
    menu: {
      items: {
        cart: 'Koszyk',
        orderHistory: 'Historia zamówień',
        paymentRequests: 'Do opłacenia',
        help: 'Centrum pomocy',
        contact: 'Kontakt',
        rules: 'Regulamin Cartel',
        privacyPolicy: 'Polityka Prywatności',
        cookiePolicy: 'Polityka Cookies',
        about: 'O aplikacji',
      }
    },
  },
  cart: {
    title: 'Koszyk',
    summary: {
      title: 'Łączna kwota',
      amount: 'Wartość koszyka',
      shipping: 'Przesyłka',
      total: 'Łączna kwota (w tym VAT)',
      placeOrderButton: 'Przejdź do kasy',
      freeShipping: 'Gratis',
    },
    items: {
      title: {
        full: 'Koszyk ({items} art.)',
        empty: 'Koszyk',
      },
      empty: {
        title: 'Koszyk jest pusty',
        subtitle: 'Tutaj pojawiać się będą artykuły ze sklepów naszych partnerów',
      },
      productsAreNotReservedNotification: 'Nie zwlekaj z zakupem, dodanie artykułów do koszyka nie oznacza ich rezerwacji. ',
    },
    deleteItem: {
      title: 'Usuń produkt z koszyka',
      confirm: 'Czy na pewno chcesz usunąć produkt z koszyka?',
      no: 'Nie',
      yes: 'Tak',
    },
  },
  checkout: {
    safe_shopping: 'Safe Shopping',
    confirmation: {
      items: {
        title: 'Zamówienie',
        empty: 'Nie udało się załadować produktów.',
      },
      billingAddress: {
        title: 'Adres rachunku',
      },
    },
    paymentRequests: {
      items: {
        title: 'Zapłać w sklepach',
      },
      paymentMethods: {
        title: 'Zapłać w sklepie',
        pay: 'Płacę',
        moreDetails: 'Pociągnij do góry, aby zobaczyć szczegóły',
        details: {
          title: 'Zamówienie',
        }
      },
      waitToRedirect: 'Za chwile nastąpi przekierowanie do płatności.',
    },
    wizard: {
      steps: {
        1: 'Adresuj',
        2: 'Potwierdź',
        3: 'Zapłać',
        4: 'Odbierz',
      }
    },
    buttons: {
      continue: 'Kontynuuj',
      confirm: 'Potwierdzam',
      abort: 'Anuluj zamówienie',
      finish: 'Wróć do sklepu',
    },
    forms: {
      billingAddress: {
        title: 'Adres rachunku',
      }
    },
    abort: {
      title: 'Anuluj zamówienie',
      confirm: 'Czy na pewno chcesz anulować zamówienie?',
      no: 'Nie',
      yes: 'Tak',
    },
    success: {
      title: 'Przyjęliśmy zamówienie',
      subtitle: 'W ciągu 24h aktywujemy promowanie ogłoszenia', // 'Wypatruj informacji o realizacji zamówienia od sprzedawcy na swojej skrzynce email.',
    },
  },
  forms: {
    errors: {
      minLength: 'Minimalna liczna liter to {length} litery',
      maxLength: 'Maksymalna liczba liter to {length} litery',
      required: 'To pole jest wymagane',
      regex: 'Wartość niezgodna z formatem',
      isEmail: 'Niepoprawny adres e-mail',
      vatNumberNotExists: 'Taki NIP nie istnieje',
    },
    fields: {
      labels: {
        firstname: 'Imię',
        lastname: 'Nazwisko',
        street: 'Ulica',
        type: {
          company: 'Firma',
          person: 'Osoba prywatna',
        },
        nip: 'NIP',
        company: 'Pełna nazwa firmy',
        houseNumber: 'Numer domu',
        flatNumber: 'Numer mieszkania',
        companyHouseNumber: 'Numer budynku',
        companyFlatNumber: 'Numer lokalu',
        city: 'Miasto',
        postCode: 'Kod pocztowy',
        country: 'Kraj',
        phoneNumber: 'Numer telefonu',
        email: 'Adres e-mail',
      }
    },
    submit: 'Submit',
    save: 'Save',
  },
  api: {
    errors: {
      default: 'Coś poszło nie tak',
      badRequest: 'Podano niepoprawne dane',
      notAllowed: 'Brak uprawnień',
      notFound: 'Nie znaleziono zasobu',
    }
  },
  countries: {
    pl: 'Polska',
  },
  shop: {
    notFound: {
      title: 'Nie znamy takiego sklepu',
      back: 'Wróć',
    },
  }
}
