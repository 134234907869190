<template>
  <f7-page
    name="cart-associate"
    @page:afterin="onAfterIn">
    <f7-block
      class="responsive">
      <f7-block
        class="padding text-align-center"
        v-if="CartStore.associating">
        <f7-preloader></f7-preloader>
      </f7-block>
    </f7-block>
  </f7-page>
</template>
<script>

import {mapActions, mapState} from 'vuex';

export default {
  props: {
    f7router: Object,
    f7route: Object,
  },
  computed: {
    ...mapState(['CartStore']),
  },
  mounted() {

  },
  methods: {
    ...mapActions('CartStore', ['associateCartItems']),
    _redirectToCart() {
      this.f7router.navigate('/cart');
    },
    onAfterIn() {
      let token = this.f7route.query.token;
      if (token) {
        this.associateCartItems({ token, })
          .then((result) => {
            if (result && !result.cartId && result.backUrl) {
              window.location.href = result.backUrl;
            } else {
              this._redirectToCart();
            }
          })
          .catch(() => {
            this._redirectToCart();
          })
      } else {
        this._redirectToCart();
      }
    }
  },
}
</script>
