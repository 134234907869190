import store from '@/js/store';
import { useI18n } from "@/js/plugins/i18n";

export default error => {
    switch (error.code) {
        case 'userIsNotAllowed': {
            error.message = useI18n().global.t('api.errors.notAllowed').toString();
            break;
        }
        case 'badRequest': {
            error.message = useI18n().global.t('api.errors.badRequest').toString();
            break;
        }
        case 'notFound': {
            error.message = useI18n().global.t('api.errors.notFound').toString();
            break;
        }
        default: {
            if (!error.message) {
                error.message = useI18n().global.t('api.errors.default').toString();
            }
            break;
        }
    }
    if (error.flash) {
        store.dispatch('ToastStore/pushError', error.message)
            .catch(console.error);
    }
    console.error(error)
    store.dispatch('LogStore/push', error)
      .catch(console.error);
    return false;
}
