import { getBillingAddressFormStoreTemplate, getBillingAddressFormApiValidationStoreTemplate, getBillingAddressFormFieldset } from "@/js/forms/billing-address";
import api from "@/js/api";

const orderItemTemplate = () => {
    return {

    };
}

const orderConfirmationTemplate = () => {
    return {
        orderItems: [],
    }
}

const initialState = () => {
    return {
        orderId: null,
        orderConfirmation: orderConfirmationTemplate(),
        billingAddressForm: getBillingAddressFormStoreTemplate(),
        billingAddressFormApiValidation: getBillingAddressFormApiValidationStoreTemplate(),
        paymentRequests: [],
        orderConfirmed: false,
        paying: false,
        pullingPaymentRequests: false,
        pushingBillingAddress: false,
        pullingBillingAddress: false,
        pullingOrderDetails: false,
        confirmingOrder: false,
    };
};

const state = initialState();

const getters = {
    billingAddressFormFieldset(state) {
        return getBillingAddressFormFieldset(state.billingAddressForm.type);
    },
    arePaymentRequestsPaid(state) {
      return !state.paymentRequests.find(r => r.status !== 'paid');
    },
}

const actions = {
    setOrder({ commit }, data) {
        commit("setOrderId", data.orderId);
    },
    pullBillingAddress({ commit, state }) {
        if (state.pullingBillingAddress) {
            return;
        }
        commit('setPullingBillingAddress', true);
        api.getOrderBillingAddress(state.orderId)
          .then((orderBillingAddress) => {
              if (_.isObject(orderBillingAddress)) {
                  commit('setBillingAddressForm', orderBillingAddress);
              }
          })
          .finally(() => {
              commit('setPullingBillingAddress', false);
          });
    },
    pushBillingAddress({ commit, state }) {
        return new Promise((resolve, reject) => {
            if (state.pushingBillingAddress) {
                return reject();
            }
            commit('setPushingBillingAddress', true);
            let argins = _.pickBy(state.billingAddressForm);
            if (argins.type === 'company') {
                argins.firstname = '';
                argins.lastname = '';
            } else {
                argins.company = '';
            }
            api.setOrderBillingAddress(state.orderId, argins)
              .then((status) => {
                  commit('setBillingAddressFormApiValidation', status.errors || {});
                  resolve(status);
              })
              .finally(() => {
                  commit('setPushingBillingAddress', false);
              });
        })
    },
    pullOrderDetails({ commit, state, }) {
        if (state.pullingOrderDetails) {
            return;
        }
        commit('setPullingOrderDetails', true);
        api.getOrderDetails(state.orderId)
          .then((order) => {
              commit("setOrderConfirmation", order);
          })
          .finally(() => {
              commit('setPullingOrderDetails', false);
          })
    },
    confirmOrder({ commit, state }) {
        if (state.confirmingOrder) {
            return Promise.resolve();
        }
        commit('setConfirmingOrder', true);
        return api.confirmOrder(state.orderId)
          .then(() => {
              commit('setOrderConfirmed', true);
              return true;
          })
          .catch((e) => {
              return false;
          })
          .finally(() => {
              commit('setConfirmingOrder', false);
          })
    },
    pullPaymentRequests({ commit, state, }) {
        if (state.pullingPaymentRequests) {
            return Promise.resolve();
        }
        commit('setPullingPaymentRequests', true);
        return api.getPaymentRequestsByOrderId(state.orderId)
          .then((paymentRequests) => {
              commit("setPaymentRequests", paymentRequests);
          })
          .finally(() => {
              commit('setPullingPaymentRequests', false);
          })
    },
    pay({ commit, state, }, data) {
        if (state.paying) {
            return;
        }
        commit('setPaying', true);
        return api.pay({ ...data, })
          .finally(() => {
              commit('setPaying', false);
          })
    },
    resetCheckout({ commit, }) {
      commit('setState', initialState());
    },
    abortCheckout({ commit }) {
        commit('setState', initialState());
    },
}

const mutations = {
    setState(state, payload) {
      _.extend(state, payload);
    },
    setOrderId(state, payload) {
      state.orderId = payload;
    },
    setOrderItems(state, payload) {
        let items = [];
        for (let orderItem of payload) {
            items.push(
              Object.assign(orderItemTemplate(), orderItem)
            );
        }
        state.orderItems = items;
    },
    setPushingBillingAddress(state, payload) {
        state.pushingBillingAddress = payload;
    },
    setPullingBillingAddress(state, payload) {
        state.pullingBillingAddress = payload;
    },
    setBillingAddressForm(state, payload) {
        _.extend(state.billingAddressForm, getBillingAddressFormStoreTemplate(), payload);
    },
    setBillingAddressFormApiValidation(state, payload) {
        _.extend(state.billingAddressFormApiValidation, getBillingAddressFormApiValidationStoreTemplate(), payload);
    },
    setPullingOrderDetails(state, payload) {
        state.pullingOrderDetails = payload;
    },
    setOrderConfirmation(state, payload) {
        state.orderConfirmation = payload;
    },
    setConfirmingOrder(state, payload) {
        state.confirmingOrder = payload;
    },
    setErrorConfirmOrder(state, payload) {
        state.errorConfirmOrder = payload;
    },
    setPullingPaymentRequests(state, payload) {
        state.pullingPaymentRequests = payload;
    },
    setPaymentRequests(state, payload) {
        state.paymentRequests = payload;
    },
    setPaying(state, payload) {
        state.paying = payload;
    },
    setOrderConfirmed(state, payload) {
        state.orderConfirmed = payload;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
