<template>
  <f7-card
    class="shop-not-found">
    <f7-card-content>
      <f7-icon material="clear"></f7-icon>
      <div class="shop-not-found-title">{{ $t('shop.notFound.title') }}</div>
      <div>{{ id }}</div>
    </f7-card-content>
  </f7-card>
</template>

<script>
export default {
  props: ['id'],
}
</script>

<style scoped>
.shop-not-found {
  padding: 4rem;
  text-align: center;
}
.shop-not-found i {
  font-size: 7rem;
  margin-bottom: 1rem;
  color: var(--f7-theme-color);
}
.shop-not-found-title {
  font-size: 1.5rem;
  margin-bottom: .5rem;
}
</style>
