<template>
  <f7-block-title medium>{{ $t('checkout.forms.billingAddress.title') }}</f7-block-title>
  <f7-card
    class="form">
    <f7-card-content>
      <f7-block
        class="padding text-align-center"
        v-if="CheckoutStore.pullingBillingAddress">
        <f7-preloader></f7-preloader>
      </f7-block>
      <form-with-validation
        ref="form"
        @submit="onSubmit"
        v-if="!CheckoutStore.pullingBillingAddress">
        <f7-list no-hairlines>
          <ul class="row no-gap">
            <component
              class="col-100"
              v-for="field of billingAddressFormFieldset"
              v-model:value="CheckoutStore.billingAddressForm[field.name]"
              v-model:apiValidation="CheckoutStore.billingAddressFormApiValidation[field.name]"
              v-bind="field"
              :ref="field.name"
              :key="field.name"
              :label="field.label"
              :is="`form-field-${field.type}`">
              <option
                :key="option.value"
                :value="option.value"
                v-for="option in field.options">
                {{ option.text }}
              </option>
            </component>
          </ul>
        </f7-list>
      </form-with-validation>
    </f7-card-content>
  </f7-card>
</template>
<script>
import FormWithValidation from "@/components/forms/form-with-validation";
import FormFieldText from "@/components/forms/fields/form-field-text";
import FormFieldRadio from "@/components/forms/fields/form-field-radio";
import FormFieldSelect from "@/components/forms/fields/form-field-select";

import { mapState, mapGetters, mapActions, } from "vuex";

export default {
  emits: ['update:valid','update:submitting'],
  components: {
    FormWithValidation,
    FormFieldText,
    FormFieldRadio,
    FormFieldSelect,
  },
  computed: {
    ...mapState(['CheckoutStore']),
    ...mapGetters('CheckoutStore', ['billingAddressFormFieldset']),
  },
  methods: {
    ...mapActions('CheckoutStore', ['pushBillingAddress']),
    submit() {
      this.$emit('update:submitting', true);
      this.$refs.form.submit();
    },
    onSubmit(status) {
      if (status.valid) {
        this.pushBillingAddress()
          .then((result) => {
            if (result.continue) {
              this.$emit('update:valid', true);
            }
          })
          .finally(() => {
            this.$emit('update:submitting', false);
          })
      } else {
        this.$emit('update:submitting', false);
      }
    }
  },
}
</script>
