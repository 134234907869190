import api from "@/js/api";

const initialState = () => {
    return {
        subscribedChannels: [],
    };
};

const state = initialState();

const getters = {

}

const actions = {
    joinSocketChannel({ commit }, data) {
        api.joinSocketChannel(data.channel)
            .then((result) => {
               if (result) {
                   commit('pushSubscribedChannel', data.channel);
               }
            });
    },
    leaveSocketChannel({ commit }, data) {
        api.leaveSocketChannel(data.channel)
            .then((result) => {
                if (result) {
                    commit('removeSubscribedChannel', data.channel);
                }
            });
    },
    rejoinSocketChannels({ commit }) {
        state.subscribedChannels.forEach(channel =>
            actions.joinSocketChannel({ commit }, { channel, }));
    },
    handleSocketConnect({ commit }) {
        actions.rejoinSocketChannels({ commit });
    },
}

const mutations = {
    pushSubscribedChannel(state, payload) {
        if (!state.subscribedChannels.includes(payload)) {
            state.subscribedChannels.push(payload);
        }
    },
    removeSubscribedChannel(state, payload) {
        state.subscribedChannels = state.subscribedChannels.filter(c => c !== payload);
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
