import { useI18n } from "@/js/plugins/i18n";

const $t = useI18n().global.t;

const billingAddressFormFieldset = () => {
    return [{
        name: 'type',
        required: true,
        type: 'radio',
        default: 'person',
        order: 1,
        values: [{
            value: 'person',
            class: 'col-100 large-50',
        }, {
            value: 'company',
            class: 'col-100 large-50',
        }],
    },{
        name: 'street',
        required: true,
        minLength: 3,
        maxLength: 40,
        type: 'text',
        order: 5,
        label: $t('forms.fields.labels.street'),
    }, {
        name: 'city',
        required: true,
        minLength: 3,
        maxLength: 30,
        type: 'text',
        order: 8,
        label: $t('forms.fields.labels.city'),
    }, {
        name: 'postCode',
        required: true,
        maxLength: 15,
        type: 'text',
        order: 9,
        label: $t('forms.fields.labels.postCode'),
    }, {
        name: 'country',
        required: true,
        type: 'select',
        default: 'pl',
        order: 10,
        label: $t('forms.fields.labels.country'),
        options: [{
            value: 'pl',
            text: $t('countries.pl'),
        }],
    }, {
        name: 'email',
        required: true,
        type: 'text',
        isEmail: true,
        order: 11,
        label: $t('forms.fields.labels.email'),
    }];
}

const billingAddressFormPersonFieldset = () => {
    return [{
        name: 'firstname',
        required: true,
        minLength: 3,
        maxLength: 20,
        type: 'text',
        order: 2,
        label: $t('forms.fields.labels.firstname'),
    },{
        name: 'lastname',
        required: true,
        minLength: 3,
        maxLength: 35,
        type: 'text',
        order: 3,
        label: $t('forms.fields.labels.lastname'),
    },{
        name: 'nip',
        type: 'text',
        order: 4,
        label: $t('forms.fields.labels.nip'),
    },{
        name: 'houseNumber',
        required: true,
        maxLength: 7,
        type: 'text',
        order: 6,
        label: $t('forms.fields.labels.houseNumber'),
    }, {
        name: 'flatNumber',
        maxLength: 7,
        type: 'text',
        order: 7,
        label: $t('forms.fields.labels.flatNumber'),
    }];
}

const billingAddressFormCompanyFieldset = () => {
    return [{
        name: 'company',
        required: true,
        maxLength: 50,
        type: 'text',
        order: 2,
        label: $t('forms.fields.labels.company'),
    },{
        name: 'nip',
        required: true,
        type: 'text',
        order: 3,
        label: $t('forms.fields.labels.nip'),
    },{
        name: 'houseNumber',
        required: true,
        maxLength: 7,
        type: 'text',
        order: 6,
        label: $t('forms.fields.labels.companyHouseNumber'),
    }, {
        name: 'flatNumber',
        maxLength: 7,
        type: 'text',
        order: 7,
        label: $t('forms.fields.labels.companyFlatNumber'),
    }];
}

const getBillingAddressFormStoreTemplate = () => {
    return _.concat(
        billingAddressFormFieldset(),
        billingAddressFormCompanyFieldset(),
        billingAddressFormPersonFieldset()
    ).reduce((p, c) => {
        p[c.name] = c.default || null;
        return p;
    }, {});
}

const getBillingAddressFormApiValidationStoreTemplate = () => {
    let tpl = getBillingAddressFormStoreTemplate();
    Object.keys(tpl).forEach(function(index) {
        tpl[index] = null;
    });
    return tpl;
}

const getBillingAddressFormFieldset = (type) => {
    let fieldset = billingAddressFormFieldset();
    if (type === 'company') {
        fieldset = _.concat(fieldset, billingAddressFormCompanyFieldset());
    } else {
        fieldset = _.concat(fieldset, billingAddressFormPersonFieldset());
    }
    fieldset = _.orderBy(fieldset, ['order'], ['asc']);
    return fieldset;
}

export {
    getBillingAddressFormStoreTemplate,
    getBillingAddressFormApiValidationStoreTemplate,
    getBillingAddressFormFieldset,
}
