<template>
  <f7-page
    name="checkout-success"
    @page:beforein="onBeforeIn"
    @page:afterin="onAfterIn">
    <f7-block class="responsive">
      <checkout-wizard-steps
        :current="4"></checkout-wizard-steps>
      <f7-card
        class="checkout-success-info">
        <f7-card-content>
          <f7-icon material="beenhere"></f7-icon>
          <div class="checkout-success-info-title">{{ $t('checkout.success.title') }}</div>
          <div class="checkout-success-info-subtitle">{{ $t('checkout.success.subtitle') }}</div>
        </f7-card-content>
      </f7-card>
      <f7-button
        fill
        large
        @click="onClickSpoglo"
        :preloader="true">{{ $t('checkout.buttons.finish') }}</f7-button>
    </f7-block>
    <checkout-footer></checkout-footer>
  </f7-page>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import CheckoutWizardSteps from "@/components/checkout/wizard-steps";
import CheckoutFooter from '@/components/checkout/footer';

export default {
  components: {
    CheckoutWizardSteps,
    CheckoutFooter,
  },
  props: {
    f7router: Object,
  },
  computed: {
    ...mapState(['CheckoutStore']),
  },
  methods: {
    ...mapActions('CheckoutStore', ['resetCheckout']),
    onBeforeIn() {

    },
    onAfterIn() {
      this.resetCheckout();
    },
    onClick() {
      this.f7router.navigate({ name: 'cart', }, { reloadCurrent: true, });
    },
    onClickSpoglo() {
      window.location.href = 'https://www.spoglo.com/potwierdzenie-dodania-ogloszenia';
    }
  }
}
</script>

<style scoped>
.wizard-steps {
  margin: 2rem 0;
}
.checkout-success-info {
  padding-top: 4rem;
  padding-bottom: 4rem;
  text-align: center;
}
.checkout-success-info i {
  font-size: 7rem;
  margin-bottom: 1rem;
  color: var(--f7-theme-color);
}
.checkout-success-info-title {
  font-size: 1.5rem;
  margin-bottom: .5rem;
}
.checkout-success-info .button {
  margin-top: 2rem;
}
.responsive > .button {
  margin-top: 0.5rem;
}
</style>
