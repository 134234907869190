import api from '@/js/api';
import {getBillingAddressFormStoreTemplate} from '@/js/forms/billing-address';

const shopTemplate = () => {
  return {
    id: null,
    name: null,
    status: null,
    vendorId: null,
    payuEnable: null,
  };
};

const initialState = () => {
  return {
    shop: null,
    pullingShop: false,
  };
};

const state = initialState();

const getters = {};

const actions = {
  pullShop({ commit, state }, { id }) {
    if (state.pullingShop) {
      return;
    }
    commit('setPullingShop', true);
    api.pullShop(id)
      .then(shop => {
        if (shop) {
          commit("setShop", shop);
        }
      })
      .finally(() => {
        commit('setPullingShop', false);
      });
  },
  resetState({ commit }) {
    commit('setState', initialState());
  },
}

const mutations = {
  setShop(state, payload) {
    state.shop = _.extend(shopTemplate(), payload);
  },
  setPullingShop(state, payload) {
    state.pullingShop = payload;
  },
  setState(state, payload) {
    _.extend(state, payload);
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
