<template>
  <div class="radio-group row">
    <f7-list-item
      radio
      v-for="def of values"
      :class="def.class"
      :name="name"
      :key="def.value"
      :value="def.value"
      :title="t(`forms.fields.labels.${name}.${def.value}`)"
      :checked="value === def.value"
      @change="onChangeValue"></f7-list-item>
  </div>
</template>

<script>
import {useI18n} from "@/js/plugins/i18n";

export default {
  props: {
    name: String,
    values: Array,
    value: String,
  },
  setup(props, { emit }) {

    const t = useI18n().global.t;

    return {
      t,
      onChangeValue: ($event) => emit('update:value', $event.target.value),
    }
  }
}
</script>

<style scoped>
.radio-group {
  margin-bottom: 20px;
}
</style>

<style>
@media screen and (min-width: 1024px) {
  .radio-group .item-inner:after {
    content: unset !important;
  }
}
</style>
