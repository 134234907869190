const initialState = () => {
    return {
        logs: [],
    }
}

const state = initialState();

const getters = {

};

const actions = {
    push({ commit }, log) {
        if (_.isString(log)) {
            commit("pushLog", log);
        } else if (_.isObject(log)) {
            commit("pushLog", JSON.stringify(log));
        }
    },
};

const mutations = {
    pushLog(state, payload) {
        state.logs.push(payload);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
