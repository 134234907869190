const initialState = () => {
    return {
        message: null,
    }
}

const state = initialState();

const getters = {

};

const actions = {
    pushError({ commit }, message) {
        commit("setMessage", {
            type: 'error',
            text: message,
        });
    },
};

const mutations = {
    setMessage(state, payload) {
        state.message = payload;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
