<template>
  <f7-block-title medium>{{ $t('checkout.confirmation.items.title') }}</f7-block-title>
  <f7-card
    class="checkout-confirmation-items-section">
    <f7-card-content>
      <f7-block
        class="padding text-align-center"
        v-if="CheckoutStore.pullingOrderDetails">
        <f7-preloader></f7-preloader>
      </f7-block>
      <f7-list
        class="checkout-confirmation-items"
        media-list
        no-hairlines
        v-if="!CheckoutStore.pullingOrderDetails && CheckoutStore.orderConfirmation.orderItems.length">
        <ul>
          <checkout-confirmation-item
            v-for="item of CheckoutStore.orderConfirmation.orderItems"
            :key="item.id"
            :shop-id="item.shopId"
            :shop-name="item.shopName"
            :id="item.id"
            :name="item.name"
            :sku="item.sku"
            :qty="item.qty"
            :type="item.type"
            :currency-code="item.currencyCode"
            :price="item.price">
          </checkout-confirmation-item>
        </ul>
      </f7-list>
      <f7-block
        class="checkout-confirmation-empty"
        v-if="!CheckoutStore.pullingOrderDetails && !CheckoutStore.orderConfirmation.orderItems.length">
        {{ $t('checkout.confirmation.items.empty') }}
      </f7-block>
    </f7-card-content>
  </f7-card>
</template>

<script>
import CheckoutConfirmationItem from '@/components/checkout/confirmation/items/item';
import {mapState} from 'vuex';

export default {
  components: {
    CheckoutConfirmationItem,
  },
  computed: {
    ...mapState(['CheckoutStore']),
  },
}
</script>


<style scoped>
.checkout-confirmation-empty {
  padding: 1rem;
  text-align: center;
}
</style>
