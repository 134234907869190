<template>
  <f7-page
    name="checkout-payment-requests"
    @page:afterin="onAfterIn">
    <f7-block class="responsive">
      <checkout-wizard-steps
        :current="3"></checkout-wizard-steps>
      <f7-block
        class="padding text-align-center"
        v-if="CheckoutStore.pullingPaymentRequests">
        <f7-preloader></f7-preloader>
      </f7-block>
      <template v-if="CheckoutStore.paymentRequests.length === 1 && CheckoutStore.paymentRequests[0].status === 'unpaid'">
        <f7-block
          class="padding text-align-center">
          <f7-preloader></f7-preloader>
          <div class="margin">{{ $t('checkout.paymentRequests.waitToRedirect') }}</div>
        </f7-block>
      </template>
      <template v-else-if="CheckoutStore.paymentRequests.length > 1">
        <checkout-payment-requests-items></checkout-payment-requests-items>
        <f7-button
          fill
          large
          @click="onPaid"
          :disabled="!arePaymentRequestsPaid"
          :preloader="true">{{ $t('checkout.buttons.continue') }}</f7-button>
      </template>
    </f7-block>
    <checkout-footer></checkout-footer>
  </f7-page>
</template>

<script>
import {mapActions, mapState, mapGetters} from 'vuex';
import CheckoutWizardSteps from "@/components/checkout/wizard-steps";
import CheckoutFooter from '@/components/checkout/footer';
import CheckoutPaymentRequestsItems from '@/components/checkout/payment-requests/items';

export default {
  components: {
    CheckoutPaymentRequestsItems,
    CheckoutWizardSteps,
    CheckoutFooter,
  },
  props: {
    f7router: Object,
  },
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters('CheckoutStore', ['arePaymentRequestsPaid']),
    ...mapState(['CheckoutStore']),
    orderId() {
      return this.CheckoutStore.orderId;
    }
  },
  methods: {
    ...mapActions('CheckoutStore', ['pullPaymentRequests', 'pay']),
    onAfterIn() {
      if (this.orderId) {
        this.pullPaymentRequests()
          .then(() => {
            this.processInstantlyOrAllowSelectPaymentRequest();
          });
      } else {
        this.f7router.navigate({ name: 'cart', }, { reloadCurrent: true, });
      }
    },
    processInstantlyOrAllowSelectPaymentRequest() {
      if (this.CheckoutStore.paymentRequests.length === 1) {
        if (this.CheckoutStore.paymentRequests[0].status === 'unpaid') {
          this.pay({ paymentRequestId: this.CheckoutStore.paymentRequests[0]._id, paymentMethod: 'payu' })
              .then((result) => {
                if (result && result.payByLink) {
                  window.location = result.payByLink;
                }
              });
        } else {
          this.onPaid();
        }
      }
    },
    onPaid() {
      this.f7router.navigate({ name: 'checkout-success', }, { reloadCurrent: true, });
    }
  }
}
</script>

<style scoped>
.wizard-steps {
  margin: 2rem 0;
}
.responsive > .button {
  margin-top: 2rem;
}
.responsive > .button {
  margin-left: calc(var(--f7-card-margin-horizontal) + var(--f7-safe-area-left));
  margin-right: calc(var(--f7-card-margin-horizontal) + var(--f7-safe-area-right));
}
</style>
