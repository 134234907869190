<template>
  <h1>{{ t('app.name') }}</h1>
  <app-menu></app-menu>
</template>

<script>
import AppMenu from '@/components/sidebar/app-left-sidebar/app-menu';
import {useI18n} from "@/js/plugins/i18n";
export default {
  components: {
    AppMenu,
  },
  computed: {
    t() {
      return useI18n().global.t;
    }
  }
}
</script>

<style scoped>
h1 {
  padding-left: calc(var(--f7-block-padding-horizontal) + var(--f7-safe-area-left));
  padding-right: calc(var(--f7-block-padding-horizontal) + var(--f7-safe-area-right));
  line-height: 64px;
  margin: unset !important;
}
</style>
