<template>
  <f7-list-item
    class="checkout-confirmation-item"
    :title="name"
    @click="onClick">
    <template #media v-if="false">
      <img src="https://www.carwashaccessories.com/wp-content/uploads/2019/11/product-placeholder-317.jpg" alt="" />
    </template>
    <template #after>

    </template>
    <template #subtitle>
      <span>{{ shopName }}</span>
    </template>
    <div class="text-align-right">
      <b>{{ fTotalPrice }}</b>
    </div>
  </f7-list-item>
</template>

<script>

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    qty: {
      type: Number,
      required: true,
    },
    price: {
      type: Number,
      required: true,
    },
    sku: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    shopId: {
      type: String,
      required: true,
    },
    shopName: {
      type: String,
      required: true,
    },
    currencyCode: {
      type: String,
      required: true,
    },
  },
  computed: {
    fTotalPrice() {
      return new Intl.NumberFormat('pl-PL', { style: 'currency', currency: this.currencyCode }).format(this.price * this.qty);
    },
    fQty() {
      return `${this.qty}`.replace(/\./, ',');
    },
    fUnit() {
      return `szt.`;
    }
  },
  methods: {
    onClick() {

    }
  }
}
</script>

<style scoped>
img {
  max-width: 80px;
}
</style>

