<template>
  <f7-list-input
    type="text"
    clear-button
    outline
    :disabled="disabled"
    :name="name"
    :label="getLabel"
    :value="value"
    :error-message-force="shouldShowErrorMessage"
    :error-message="getErrorMessage"
    :placeholder="placeholder"
    @change="onChangeValue"></f7-list-input>
</template>

<script>
import { useVuelidate, } from "@vuelidate/core";
import { computed, watch } from "vue";
import { useI18n } from "@/js/plugins/i18n";
import { customValidator } from "@/js/plugins/vuelidate";
import isEmail from 'validator/es/lib/isEmail';

export default {
  emits: ['update:value'],
  props: {
    label: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    value: {
      type: String,
    },
    required: {
      type: Boolean,
    },
    minLength: {
      type: Number,
    },
    maxLength: {
      type: Number,
    },
    name: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    regex: {
      type: RegExp,
    },
    isEmail: {
      type: Boolean,
    },
    apiValidation: {
      type: String,
    },
  },
  setup(props, { emit, }) {

    const t = useI18n().global.t;

    const rules = {
      v: {
        minLength: customValidator(() => {
          return !props.value || !props.minLength || props.value.length >= props.minLength;
        },
          t('forms.errors.minLength', { length: props.minLength, })
        ),
        maxLength: customValidator(() => {
          return !props.value || !props.maxLength || props.value.length <= props.maxLength;
        },
          t('forms.errors.maxLength', { length: props.maxLength, })
        ),
        required: customValidator(() => {
          return !(props.required && !props.value);
        },
          t('forms.errors.required')
        ),
        regex: customValidator(() => {
          return !props.value || !props.regex || props.regex.test(props.value);
        },
          t('forms.errors.regex')
        ),
        isEmail: customValidator(() => {
          return !props.value || !props.isEmail || isEmail(props.value);
        },
          t('forms.errors.isEmail')
        ),
      },
    }

    const vuelidate = useVuelidate(rules, { v: props.value, });

    watch(
        () => props.value,
        () => {
          runValidation();
        }
    )

    if (props.value) {
      runValidation();
    }

    const getErrorMessage = computed(() => {
      if (_.isString(props.apiValidation)) {
        return t(props.apiValidation);
      }
      let list = vuelidate.value.v.$errors;
      if (list.length > 0) {
        return list[0].$message;
      }
      return null;
    });

    const shouldShowErrorMessage = computed(() => {
      return _.isString(props.apiValidation) || vuelidate.value.v.$error;
    });

    const getLabel = computed(() => {
      return props.label + (props.required ? '*' : '');
    });

    function onChangeValue($event) {
      emit('update:value', $event.target.value);
    }

    function runValidation() {
      vuelidate.value.$touch();
      if (false === vuelidate.value.$invalid) {
        vuelidate.value.$reset();
      }
      return false === vuelidate.value.$invalid;
    }

    return {
      vuelidate,
      onChangeValue,
      getErrorMessage,
      t,
      getLabel,
      runValidation,
      shouldShowErrorMessage,
    }
  },
}
</script>
