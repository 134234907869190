import io from "@/js/socket-io";
import apiErrorHandler from "@/js/api/error-handler";
import { useI18n } from "@/js/plugins/i18n";

const api = {

  getPaymentRequestsByOrderId(orderId) {
    return new Promise(async (resolve, reject) => {
      return this._getAccessToken().then(async accessToken => {
        console.debug(`API: getPaymentRequests()`);
        io.socket.request({
          method: 'GET',
          url: `/api/v1/payments/requests/order/${orderId}`,
          headers: {
            authorization: 'Bearer ' + accessToken,
          },
        }, (body, JWR) => {
          if (JWR.statusCode === 200) {
            if (_.isObject(body) && _.isArray(body.paymentRequests)) {
              return resolve(body.paymentRequests);
            }
            return resolve([]);
          }
          let error = new Error();
          error.flash = true;
          error.message = useI18n().global.t('api.errors.default').toString();
          if (JWR.headers['x-exit']) {
            error.code = JWR.headers['x-exit'];
            switch (JWR.headers['x-exit']) {

            }
          }
          reject(error);
        });
      });
    })
      .catch(apiErrorHandler);
  },

  pay({paymentRequestId,paymentMethod}) {
    return new Promise(async (resolve, reject) => {
      return this._getAccessToken().then(async accessToken => {
        console.debug(`API: pay()`);
        io.socket.request({
          method: 'POST',
          url: `/api/v1/payments/pay/${paymentRequestId}`,
          headers: {
            authorization: 'Bearer ' + accessToken,
          },
          data: {
            paymentMethod
          }
        }, (body, JWR) => {
          if (JWR.statusCode === 200) {
            if (_.isObject(body)) {
              return resolve(body);
            }
            return resolve({});
          }
          let error = new Error();
          error.flash = true;
          error.message = useI18n().global.t('api.errors.default').toString();
          if (JWR.headers['x-exit']) {
            error.code = JWR.headers['x-exit'];
            switch (JWR.headers['x-exit']) {

            }
          }
          reject(error);
        });
      });
    })
      .catch(apiErrorHandler);
  },

};

export default api;
