<template>
  <f7-list-input
    type="select"
    clear-button
    outline
    :name="name"
    :label="getLabel"
    :disabled="disabled"
    :value="value"
    :error-message-force="vuelidate.v.$error"
    :error-message="getErrorMessage"
    :placeholder="placeholder"
    @change="onChangeValue">
    <slot name="default"></slot>
  </f7-list-input>
</template>

<script>
import {useI18n} from '@/js/plugins/i18n';
import {customValidator} from '@/js/plugins/vuelidate';
import {useVuelidate} from '@vuelidate/core';
import {computed, watch} from 'vue';

export default {
  emits: ['update:value'],
  props: {
    label: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    required: {
      type: Boolean,
    },
    value: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    name: {
      type: String,
    },
  },
  setup(props, { emit, }) {

    const t = useI18n().global.t;

    const rules = {
      v: {
        required: customValidator(() => {
              return !(props.required && !props.value);
            },
            t('forms.errors.required')
        ),
      },
    }

    const vuelidate = useVuelidate(rules, { v: props.value, });

    watch(
        () => props.value,
        () => {
          runValidation();
        }
    )

    if (props.value) {
      runValidation();
    }

    const getErrorMessage = computed(() => {
      let list = vuelidate.value.v.$errors;
      if (list.length > 0) {
        return list[0].$message;
      }
      return null;
    });

    const getLabel = computed(() => {
      return props.label + (props.required ? '*' : '');
    });

    function onChangeValue($event) {
      emit('update:value', $event.target.value);
    }

    function runValidation() {
      vuelidate.value.$touch();
      if (false === vuelidate.value.$invalid) {
        vuelidate.value.$reset();
      }
      return false === vuelidate.value.$invalid;
    }

    return {
      vuelidate,
      onChangeValue,
      getErrorMessage,
      t,
      getLabel,
      runValidation,
    }
  }
}
</script>
