<template>
  <div class="app-menu">
    <f7-list menu-list v-if="false">
      <f7-list-item
        link="/cart"
        view=".view-main"
        :title="t('app.menu.items.cart')">
        <template #media>
          <f7-icon material="shopping_cart" />
        </template>
      </f7-list-item>
      <f7-list-item
        link
        view=".view-main"
        :title="t('app.menu.items.orderHistory')"
        v-if="false">
        <template #media>
          <f7-icon material="history" />
        </template>
      </f7-list-item>
      <f7-list-item
        link
        view=".view-main"
        :title="t('app.menu.items.paymentRequests')"
        v-if="false">
        <template #media>
          <f7-icon material="payments" />
        </template>
      </f7-list-item>
    </f7-list>
    <f7-list menu-list>
<!--      <f7-list-item-->
<!--        :link="staticPagesUrl+'/centrum-pomocy.html'"-->
<!--        external-->
<!--        :title="t('app.menu.items.help')">-->
<!--      </f7-list-item>-->
      <f7-list-item
        link="https://www.spoglo.com/kontakt"
        external
        :title="t('app.menu.items.contact')">
      </f7-list-item>
<!--      <f7-list-item-->
<!--        :link="staticPagesUrl+'/regulamin.html'"-->
<!--        external-->
<!--        :title="t('app.menu.items.rules')">-->
<!--      </f7-list-item>-->
      <f7-list-item
        :link="staticPagesUrl+'/polityka-prywatnosci.html'"
        external
        :title="t('app.menu.items.privacyPolicy')">
      </f7-list-item>
<!--      <f7-list-item-->
<!--        :link="staticPagesUrl+'/o-aplikacji.html'"-->
<!--        external-->
<!--        :title="t('app.menu.items.about')">-->
<!--      </f7-list-item>-->
      <f7-list-item
        link="https://www.spoglo.com"
        external
        title="Wróć do Spoglo">
      </f7-list-item>
    </f7-list>
  </div>
</template>

<script>
import {useI18n} from "@/js/plugins/i18n";
export default {
  computed: {
    t() {
      return useI18n().global.t;
    },
    staticPagesUrl() {
      return process.env.STATIC_PAGES_URL;
    }
  }
}
</script>

<style>
.app-menu .item-inner {
  margin-left: unset !important;
}
.app-menu > .list:nth-child(1) {
  margin-top: unset !important;
}
</style>
