<template>
  <f7-app v-bind="f7params" >

    <f7-panel left reveal>
      <f7-view>
        <f7-page>
          <app-left-sidebar></app-left-sidebar>
        </f7-page>
      </f7-view>
    </f7-panel>

    <!-- Your main view, should have "view-main" class -->
    <f7-view main class="safe-areas" url="/" :browser-history="true"></f7-view>

  </f7-app>
</template>
<script>
  import { ref, onMounted, watch } from 'vue';
  import { useStore } from 'vuex';
  import { f7, f7ready } from 'framework7-vue';
  import { getDevice }  from 'framework7/lite-bundle';
  import cordovaApp from '../js/cordova-app.js';

  import routes from '../js/routes.js';
  import AppLeftSidebar from "@/components/sidebar/app-left-sidebar";

  export default {
    components: {
      AppLeftSidebar

    },
    setup() {
      const device = getDevice();
      const store = useStore();

      const f7params = {
        name: 'Cartel',
        theme: 'auto',
        id: 'io.framework7.myapp',
        routes: routes,
        input: {
          scrollIntoViewOnFocus: device.cordova && !device.electron,
          scrollIntoViewCentered: device.cordova && !device.electron,
        },
        statusbar: {
          iosOverlaysWebView: true,
          androidOverlaysWebView: false,
        },
      };

      const authAction = () => store.dispatch('UserStore/authAction')

      watch(
        () => store.state.ToastStore.message,
        (message) => {
          if (_.isObject(message)) {
            let toastOptions = {
              text: message.text,
              closeTimeout: 2000,
            };
            if (message.type) {
              toastOptions.cssClass = 'toast-' + message.type;
            }
            f7.toast.create(toastOptions).open();
          }
        }
      );

      onMounted(() => {
        f7ready(() => {
          if (device.cordova) {
            cordovaApp.init(f7);
          }
          authAction();
        });
      });

      return {
        f7params,
      }
    }
  }
</script>
