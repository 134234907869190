<template>
  <f7-page
    name="cart"
    @page:beforein="onBeforeIn"
    @page:beforeout="onBeforeOut">
    <f7-navbar>
      <f7-nav-left>
        <f7-link panel-open="left"><f7-icon material="menu"></f7-icon></f7-link>
      </f7-nav-left>
      <f7-nav-title>{{ $t('cart.title') }}</f7-nav-title>
    </f7-navbar>
    <f7-block
      class="responsive">
      <f7-row no-gap>
        <f7-col width="100" large="60">
          <cart-items></cart-items>
        </f7-col>
        <f7-col width="100" large="40">
          <cart-summary
            :f7router="f7router"></cart-summary>
        </f7-col>
      </f7-row>
    </f7-block>
  </f7-page>
</template>
<script>
import CartItems from "@/components/cart/items"
import CartSummary from "@/components/cart/summary";

import {mapActions, mapState} from 'vuex';

export default {
  props: {
    f7router: Object,
  },
  components: {
    CartItems,
    CartSummary,
  },
  mounted() {
    this.pullCartItems();
  },
  methods: {
    ...mapActions('CartStore', ['pullCartItems']),
    onBeforeIn() {

    },
    onBeforeOut() {

    },
  }
}
</script>

<style>
.cart-section {
  background: #fff;
}
</style>
