<template>
  <ol class="wizard-steps" :class="`steps-${steps}`">
    <li
      v-for="step in steps"
      :key="step"
      :class="{'current': step === current }"
      @click="onClickStep(step)">
      {{ $t(`checkout.wizard.steps.${step}`) }}
    </li>
  </ol>
</template>

<script>
export default {
  props: {
    current: Number,
    f7router: Object,
  },
  data() {
    return {
      steps: 4,
      links: {
        1: { name: 'checkout-address', },
      }
    }
  },
  methods: {
    onClickStep(step) {
      if (this.f7router && this.links[step] && step < this.current) {
        this.f7router.navigate(this.links[step]);
      }
    }
  }
}
</script>
<style scoped>
ol {
  position: relative;
  overflow: hidden;
  counter-reset: wizard;
  padding: 0;
}

ol.steps-5 li {
  width: 20%;
}

ol.steps-4 li {
  width: 25%;
}

li {
  position: relative;
  float: left;
  text-align: center;
  color: rgb(0, 122, 255);
  list-style: none;
}

.current ~ li {
  color: #555;
}

li:before {
  counter-increment: wizard;
  content: counter(wizard);
  display: block;
  color: rgb(0, 122, 255);
  background-color: #fff;
  border: 2px solid rgb(0, 122, 255);
  text-align: center;
  width: 2em;
  height: 2em;
  line-height: 2em;
  border-radius: 2em;
  position: relative;
  left: 50%;
  margin-bottom: 1em;
  margin-left: -1em;
  z-index: 1;
}

.current:before {
  background-color: rgb(0, 122, 255);
  color: #fff;
}

.current ~ li:before {
  background-color: white;
  color: #555;
  border-color: #555;
}

li + li:after {
  content: "";
  display: block;
  width: 100%;
  background-color: rgb(0, 122, 255);
  height: 2px;
  position: absolute;
  left: -50%;
  top: 1em;
  z-index: 0;
}

.current ~ li:after {
  background-color: #555;
}
</style>
