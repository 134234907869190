<template>
  <f7-page
    name="checkout-confirmation"
    @page:afterin="onAfterIn">
    <f7-block class="responsive">
      <checkout-wizard-steps
        :f7router="f7router"
        :current="2"></checkout-wizard-steps>
      <f7-row no-gap>
        <f7-col width="100" large="60">
          <checkout-confirmation-items></checkout-confirmation-items>
        </f7-col>
        <f7-col width="100" large="40">
          <checkout-confirmation-billing-address
            :f7router="f7router"></checkout-confirmation-billing-address>
          <checkout-confirmation-confirm-button
            :f7router="f7router"></checkout-confirmation-confirm-button>
        </f7-col>
      </f7-row>
    </f7-block>
    <checkout-footer></checkout-footer>
  </f7-page>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import CheckoutWizardSteps from "@/components/checkout/wizard-steps";
import CheckoutConfirmationItems from "@/components/checkout/confirmation/items";
import CheckoutConfirmationBillingAddress from "@/components/checkout/confirmation/billing-address";
import CheckoutConfirmationConfirmButton from "@/components/checkout/confirmation/confirm-button";
import CheckoutFooter from '@/components/checkout/footer';

export default {
  components: {
    CheckoutWizardSteps,
    CheckoutConfirmationItems,
    CheckoutConfirmationBillingAddress,
    CheckoutConfirmationConfirmButton,
    CheckoutFooter,
  },
  props: {
    f7router: Object,
  },
  data() {
    return {

    }
  },
  computed: {
    ...mapState(['CheckoutStore']),
  },
  methods: {
    ...mapActions('CheckoutStore', ['pullOrderDetails','confirmOrder']),
    onAfterIn() {
      if (!this.CheckoutStore.orderId) {
        this.f7router.navigate({ name: 'cart', }, { reloadCurrent: true, });
        return;
      }
      if (this.CheckoutStore.orderConfirmed) {
        this.f7router.navigate({ name: 'checkout-payment-requests', }, { reloadCurrent: true, });
        return;
      }
      this.pullOrderDetails()
    },
  }
}
</script>

<style scoped>
.wizard-steps {
  margin: 2rem 0;
}
</style>
