// Import Vue
import { createApp } from 'vue';

// Import Framework7
import Framework7 from 'framework7/lite-bundle';

// Import Framework7-Vue Plugin
import Framework7Vue, { registerComponents } from 'framework7-vue/bundle';

// Import Framework7 Styles
import 'framework7/framework7-bundle.css';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.less';

// Import momentjs
import moment from 'moment';
moment.locale('pl');

// Import App Component
import App from '../components/app.vue';

import store from '../js/store';

import { useVuelidate } from '@/js/plugins/vuelidate';
import { useI18n } from '@/js/plugins/i18n';

// Import firebase
import "./plugins/firebase";

// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);

// Init App
const app = createApp(App);

app.use(useI18n());
app.use(useVuelidate());
app.use(store);

// Register Framework7 Vue components
registerComponents(app);

// Mount the app
app.mount('#app');
