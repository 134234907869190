import api from "@/js/api";
import tools from "@/js/tools";

const settingsTemplate = () => {
    return {
        updatedAt: null,
        currency: 'PLN',
        locale: 'pl-PL',
    }
};

const initialState = () => {
    return {
        settings: settingsTemplate(),
        loading: false,
    };
};

const state = initialState();

const getters = {
    shouldPullSettings(state) {
        return !state.settings.updatedAt;
    },
    formatCash(state) {
        return function (value) {
            return tools.formatCash(value, state.settings.locale, state.settings.currency);
        }
    },
}

const actions = {
    pullSettings({ commit }) {
        commit('setLoading', true);
        api.pullSettings()
            .then(settings => {
                if (settings) {
                    commit("setSettings", settings);
                }
            })
            .finally(() => {
                commit('setLoading', false);
            });
    },
    updateSettings({ commit }, data) {
        let inputs = {
            ...data,
        };
        api.updateSettings(inputs)
            .then(settings => {
                if (settings) {
                    commit("setSettings", settings);
                }
            });
    },
    resetState({ commit }) {
        commit('setState', initialState());
    },
}

const mutations = {
    setSettings(state, payload) {
        state.settings = payload;
    },
    setLoading(state, payload) {
        state.loading = payload;
    },
    setState(state, payload) {
        _.extend(state, payload);
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
