import io from "@/js/socket-io";
import apiErrorHandler from "@/js/api/error-handler";
import { useI18n } from "@/js/plugins/i18n";

const api = {

    joinSocketChannel(channel) {
        return new Promise(async (resolve, reject) => {
            return this._getAccessToken().then(async accessToken => {
                console.debug(`API: channels.join(${channel})`);
                io.socket.request({
                    method: 'POST',
                    url: `/api/v1/socket/join-channel`,
                    data: {
                        channel,
                    },
                    headers: {
                        authorization: 'Bearer ' + accessToken,
                    }
                }, (body, JWR) => {
                    if (JWR.statusCode === 200) {
                        if (body === 'OK') {
                            return resolve(true);
                        }
                        return resolve(false);
                    }
                    let error = new Error();
                    error.flash = true;
                    error.message = useI18n().global.t('api.errors.default').toString();
                    if (JWR.headers['x-exit']) {
                        error.code = JWR.headers['x-exit'];
                        switch (JWR.headers['x-exit']) {

                        }
                    }
                    reject(error);
                });
            });
        })
            .catch(apiErrorHandler);
    },

    leaveSocketChannel(channel) {
        return new Promise(async (resolve, reject) => {
            return this._getAccessToken().then(async accessToken => {
                console.debug(`API: channels.leave(${channel})`);
                io.socket.request({
                    method: 'POST',
                    url: `/api/v1/socket/leave-channel`,
                    data: {
                        channel,
                    },
                    headers: {
                        authorization: 'Bearer ' + accessToken,
                    }
                }, (body, JWR) => {
                    if (JWR.statusCode === 200) {
                        if (body === 'OK') {
                            return resolve(true);
                        }
                        return resolve(false);
                    }
                    let error = new Error();
                    error.flash = true;
                    error.message = useI18n().global.t('api.errors.default').toString();
                    if (JWR.headers['x-exit']) {
                        error.code = JWR.headers['x-exit'];
                        switch (JWR.headers['x-exit']) {

                        }
                    }
                    reject(error);
                });
            });
        })
            .catch(apiErrorHandler);
    },

};

export default api;
