import api from "./api";
import CartPage from '../pages/cart.vue';
import CartAssociate from '../pages/cart-associate.vue';
import CheckoutAddressPage from '../pages/checkout-address.vue';
import CheckoutConfirmationPage from '../pages/checkout-confirmation.vue';
import CheckoutPaymentRequests from '../pages/checkout-payment-requests.vue';
import CheckoutSuccess from '../pages/checkout-success.vue';
import Shop from '../pages/shop.vue';
import NotFoundPage from '../pages/404.vue';

const routes = [
  {
    name: 'home',
    path: '/',
    component: CartPage,
  },
  {
    name: 'cart',
    path: '/cart',
    component: CartPage,
  },
  {
    name: 'cart-associate',
    path: '/cart/associate',
    component: CartAssociate,
  },
  {
    name: 'checkout-address',
    path: '/checkout/address',
    component: CheckoutAddressPage,
  },
  {
    name: 'checkout-confirmation',
    path: '/checkout/confirmation',
    component: CheckoutConfirmationPage,
  },
  {
    name: 'checkout-payment-requests',
    path: '/checkout/payment-requests',
    component: CheckoutPaymentRequests,
  },
  {
    name: 'checkout-success',
    path: '/checkout/success',
    component: CheckoutSuccess,
  },
  {
    name: 'shop',
    path: '/shop/:id',
    component: Shop,
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
