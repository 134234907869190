import { createStore } from 'vuex'
import SecureLS from "secure-ls";
import createPersistedState from "vuex-persistedstate";
import _ from 'lodash';

import ToastStore from '@/js/store/toast.store';
import UserStore from '@/js/store/user.store';
import SocketsStore from '@/js/store/sockets.store';
import CartStore from '@/js/store/cart.store';
import SettingsStore from '@/js/store/settings.store';
import CheckoutStore from '@/js/store/checkout.store';
import LogStore from '@/js/store/log.store';
import ShopStore from '@/js/store/shop.store';

const ls = new SecureLS({ isCompression: false, });

const store = createStore({
    modules: {
        UserStore,
        ToastStore,
        SocketsStore,
        CartStore,
        SettingsStore,
        CheckoutStore,
        LogStore,
        ShopStore,
    },
    state: {

    },
    mutations: {

    },
    actions: {
        resetState() {
            for (let action of _.keys(this._actions)) {
                if (action !== 'resetState' && action.includes('resetState')) {
                    store.dispatch(action).catch(console.error);
                }
            }
        },
    },
    getters: {

    },
    plugins: [
        createPersistedState({
            paths: [
                'UserStore.user',
                'SettingsStore.settings',
                'CheckoutStore.orderId',
                'CheckoutStore.orderConfirmed',
            ],
            storage: {
                getItem: (key) => ls.get(key),
                setItem: (key, value) => ls.set(key, value),
                removeItem: (key) => ls.remove(key),
            },
        }),
    ]
});

export default store;
