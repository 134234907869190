<template>
  <f7-block>
    <h1>{{ name }}</h1>
  </f7-block>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
  }
}
</script>
