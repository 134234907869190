<template>
  <f7-block class="checkout-footer">

  </f7-block>
</template>

<style scoped>
.checkout-footer {
  /*border-top: 1px solid #555;*/
}
</style>
