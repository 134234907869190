import io from "@/js/socket-io";
import apiErrorHandler from "@/js/api/error-handler";
import { useI18n } from "@/js/plugins/i18n";

const api = {

    pullCartItems() {
        return new Promise(async (resolve, reject) => {
            return this._getAccessToken().then(async accessToken => {
                console.debug(`API: pullCartItems()`);
                io.socket.request({
                    method: 'GET',
                    url: `/api/v1/carts/items`,
                    headers: {
                        authorization: 'Bearer ' + accessToken,
                    }
                }, (body, JWR) => {
                    if (JWR.statusCode === 200) {
                        if (_.isObject(body)) {
                            return resolve(body);
                        }
                        return resolve(null);
                    }
                    let error = new Error();
                    error.flash = true;
                    error.message = useI18n().global.t('api.errors.default').toString();
                    if (JWR.headers['x-exit']) {
                        error.code = JWR.headers['x-exit'];
                        switch (JWR.headers['x-exit']) {

                        }
                    }
                    reject(error);
                });
            });
        })
            .catch(apiErrorHandler);
    },

    associateCartItems(token) {
        return new Promise(async (resolve, reject) => {
            return this._getAccessToken().then(async accessToken => {
                console.debug(`API: associateCartItems()`);
                io.socket.request({
                    method: 'POST',
                    url: `/api/v1/carts/associate`,
                    data: {
                        token,
                    },
                    headers: {
                        authorization: 'Bearer ' + accessToken,
                    }
                }, (body, JWR) => {
                    if (JWR.statusCode === 200) {
                        if (_.isObject(body)) {
                            return resolve(body);
                        }
                        return resolve(null);
                    }
                    let error = new Error();
                    error.flash = true;
                    error.message = useI18n().global.t('api.errors.default').toString();
                    if (JWR.headers['x-exit']) {
                        error.code = JWR.headers['x-exit'];
                        switch (JWR.headers['x-exit']) {

                        }
                    }
                    reject(error);
                });
            });
        })
          .catch(apiErrorHandler);
    },

    deleteCartItem(itemId) {
        return new Promise(async (resolve, reject) => {
            return this._getAccessToken().then(async accessToken => {
                console.debug(`API: deleteCartItem()`);
                io.socket.request({
                    method: 'DELETE',
                    url: `/api/v1/carts/items/${itemId}`,
                    headers: {
                        authorization: 'Bearer ' + accessToken,
                    }
                }, (body, JWR) => {
                    if (JWR.statusCode === 200) {
                        return resolve(true);
                    }
                    let error = new Error();
                    error.flash = true;
                    error.message = useI18n().global.t('api.errors.default').toString();
                    if (JWR.headers['x-exit']) {
                        error.code = JWR.headers['x-exit'];
                        switch (JWR.headers['x-exit']) {

                        }
                    }
                    reject(error);
                });
            });
        });
    }

};

export default api;
