import io from "@/js/socket-io";
import apiErrorHandler from "@/js/api/error-handler";
import { useI18n } from "@/js/plugins/i18n";

const api = {

  placeOrder(items) {
    return new Promise(async (resolve, reject) => {
      return this._getAccessToken().then(async accessToken => {
        console.debug(`API: placeOrder()`);
        io.socket.request({
          method: 'POST',
          url: `/api/v1/orders`,
          headers: {
            authorization: 'Bearer ' + accessToken,
          },
          data: {
            items,
          }
        }, (body, JWR) => {
          if (JWR.statusCode === 200) {
            if (_.isObject(body)) {
              return resolve(body);
            }
            return resolve(null);
          }
          let error = new Error();
          error.flash = true;
          error.message = useI18n().global.t('api.errors.default').toString();
          if (JWR.headers['x-exit']) {
            error.code = JWR.headers['x-exit'];
            switch (JWR.headers['x-exit']) {

            }
          }
          reject(error);
        });
      });
    })
      .catch(apiErrorHandler);
  },

  getOrderBillingAddress(orderId) {
    return new Promise((resolve, reject) => {
      return this._getAccessToken().then(accessToken => {
        console.debug(`API: getOrderBillingAddress()`);
        io.socket.request({
          method: 'GET',
          url: `/api/v1/orders/${orderId}/billingAddress`,
          headers: {
            authorization: 'Bearer ' + accessToken,
          },
        }, (body, JWR) => {
          if (JWR.statusCode === 200) {
            if (_.isObject(body)) {
              resolve(body.billingAddress);
            }
          }
          let error = new Error();
          error.JWR = JWR;
          error.flash = true;
          reject(error);
        });
      });
    })
      .catch(apiErrorHandler);
  },

  setOrderBillingAddress(orderId, billingAddress) {
    return new Promise((resolve, reject) => {
      return this._getAccessToken().then(accessToken => {
        console.debug(`API: setOrderBillingAddress()`);
        io.socket.request({
          method: 'PUT',
          url: `/api/v1/orders/${orderId}/billingAddress`,
          headers: {
            authorization: 'Bearer ' + accessToken,
          },
          data: {
            ...billingAddress,
          }
        }, (body, JWR) => {
          if (JWR.statusCode === 200) {
            if (body.status === 'validationFailed') {
              return resolve({
                continue: false,
                errors: body.errors,
              });
            } else if (body.status === 'ok') {
              return resolve({
                continue: true,
              });
            }
          }
          let error = new Error();
          error.JWR = JWR;
          error.flash = true;
          reject(error);
        });
      });
    })
      .catch(apiErrorHandler);
  },

  getOrderDetails(orderId) {
    return new Promise(async (resolve, reject) => {
      return this._getAccessToken().then(async accessToken => {
        console.debug(`API: getOrderDetails()`);
        io.socket.request({
          method: 'GET',
          url: `/api/v1/orders/${orderId}`,
          headers: {
            authorization: 'Bearer ' + accessToken,
          },
        }, (body, JWR) => {
          if (JWR.statusCode === 200) {
            if (_.isObject(body)) {
              return resolve(body.order);
            }
            return resolve(null);
          }
          let error = new Error();
          error.flash = true;
          error.message = useI18n().global.t('api.errors.default').toString();
          if (JWR.headers['x-exit']) {
            error.code = JWR.headers['x-exit'];
            switch (JWR.headers['x-exit']) {

            }
          }
          reject(error);
        });
      });
    })
      .catch(apiErrorHandler);
  },

  confirmOrder(orderId) {
    return new Promise(async (resolve, reject) => {
      return this._getAccessToken().then(async accessToken => {
        console.debug(`API: confirmOrder()`);
        io.socket.request({
          method: 'POST',
          url: `/api/v1/orders/${orderId}/confirm`,
          headers: {
            authorization: 'Bearer ' + accessToken,
          },
        }, (body, JWR) => {
          if (JWR.statusCode === 200) {
            if (_.isObject(body)) {
              return resolve(body);
            }
            return resolve(null);
          }
          let error = new Error();
          error.flash = true;
          error.message = useI18n().global.t('api.errors.default').toString();
          if (JWR.headers['x-exit']) {
            error.code = JWR.headers['x-exit'];
            switch (JWR.headers['x-exit']) {

            }
          }
          reject(error);
        });
      });
    })
      .catch(apiErrorHandler);
  },

};

export default api;
