<template>
  <f7-page
    name="shop"
    @page:beforein="onBeforeIn">
    <f7-navbar back-link="Back">
      <f7-nav-title>{{ $t('app.name') }}</f7-nav-title>
    </f7-navbar>
    <f7-block
      class="padding text-align-center"
      v-if="ShopStore.pullingShop">
      <f7-preloader></f7-preloader>
    </f7-block>
    <f7-block
      class="responsive"
      v-if="!ShopStore.pullingShop && ShopStore.shop">
      <shop-details
        :name="ShopStore.shop.name"></shop-details>
    </f7-block>
    <f7-block
      class="responsive"
      v-if="!ShopStore.pullingShop && !ShopStore.shop">
      <shop-not-found
        :id="shopId"
        :f7router="f7router"
        ></shop-not-found>
    </f7-block>
  </f7-page>
</template>

<script>

import {mapActions, mapState} from 'vuex';
import ShopDetails from "@/components/shops/shop-details";
import ShopNotFound from "@/components/shops/shop-not-found";

export default {
  components:{
    ShopDetails,
    ShopNotFound,
  },
  props: {
    f7router: Object,
    f7route: Object,
    $f7: Object,
  },
  computed: {
    ...mapState(['ShopStore',]),
    shopId() {
      return this.f7route.params.id;
    }
  },
  methods: {
    ...mapActions('ShopStore', ['pullShop',]),
    onBeforeIn() {
      this.pullShop({id: this.shopId});
    }
  },
}
</script>

<style scoped>
.shop-details {
  margin-top: 1rem;
}
</style>
