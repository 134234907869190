<template>
  <f7-list-item @click="onClick">
    shopId: {{ shopId }}<br />
    status: {{ status }}<br />
    amount: {{ amount }}<br />
    currencyCode: {{ currencyCode }}<br />
  </f7-list-item>
</template>

<script>

export default {
  props: {
    shopId: {
      type: String,
      required: true,
    },
    amount: {
      type: Number,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
    currencyCode: {
      type: String,
      required: true,
    },
  },
  methods: {
    onClick() {

    }
  }
}
</script>
