<template>
  <f7-block
    class="cart-empty">
    <f7-icon material="shopping_basket"></f7-icon>
    <div class="cart-empty-title">{{ $t('cart.items.empty.title') }}</div>
    <div class="cart-empty-subtitle">{{ $t('cart.items.empty.subtitle') }}</div>
  </f7-block>
</template>

<script>
export default {

}
</script>

<style scoped>
.cart-empty {
  padding-top: 4rem;
  padding-bottom: 4rem;
  text-align: center;
}
.cart-empty i {
  font-size: 7rem;
  margin-bottom: 1rem;
  color: var(--f7-theme-color);
}
.cart-empty-title {
  font-size: 1.5rem;
  margin-bottom: .5rem;
}
</style>
