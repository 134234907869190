import { createI18n } from 'vue-i18n';
import lang from '@/js/lang';

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: 'pl',
  messages: lang
});

export function useI18n() {
  return i18n;
}
