<template>
  <f7-page
    name="checkout-address"
    @page:beforein="onBeforeIn"
    @page:afterin="onAfterIn">
    <f7-block class="responsive">
      <checkout-wizard-steps
        :current="1"></checkout-wizard-steps>
      <checkout-billing-address
        ref="form-billing-address"
        class="padding-bottom"
        v-model:submitting="isSubmitting"
        v-model:valid="isBillingAddressValid"></checkout-billing-address>
      <f7-button
        class="button-continue"
        fill
        large
        preloader
        :loading="isLoading"
        @click="onContinue"
        v-if="!CheckoutStore.pullingBillingAddress">{{ $t('checkout.buttons.continue') }}</f7-button>
      <f7-button
        fill
        large
        color="red"
        @click="onAbort"
        v-if="!CheckoutStore.pullingBillingAddress">{{ $t('checkout.buttons.abort') }}</f7-button>
    </f7-block>
    <checkout-footer></checkout-footer>
  </f7-page>
</template>
<script>
import CheckoutWizardSteps from "@/components/checkout/wizard-steps";
import CheckoutBillingAddress from "@/components/checkout/address/billing-address";
import CheckoutFooter from "@/components/checkout/footer";
import {mapActions, mapState,} from 'vuex';
import { f7 } from 'framework7-vue';

export default {
  components: {
    CheckoutWizardSteps,
    CheckoutBillingAddress,
    CheckoutFooter,
  },
  props: {
    f7router: Object,
    $f7: Object,
  },
  data() {
    return {
      isBillingAddressValid: false,
      isSubmitting: false,
    }
  },
  computed: {
    ...mapState(['CheckoutStore']),
    isLoading() {
      return this.CheckoutStore.pullingBillingAddress || this.isSubmitting;
    },
  },
  watch: {
    isBillingAddressValid(v) {
      if (v) {
        this.f7router.navigate({ name: 'checkout-confirmation', });
      }
    },
  },
  methods: {
    ...mapActions('CheckoutStore', ['pullBillingAddress', 'abortCheckout']),
    onContinue() {
      if (this.isSubmitting) {
        return;
      }
      this.$refs['form-billing-address'].submit();
    },
    onAbort() {
      f7.dialog.create({
        title: this.$t('checkout.abort.title'),
        text: this.$t('checkout.abort.confirm'),
        buttons: [{
          text: this.$t('checkout.abort.no'),
        }, {
          text: this.$t('checkout.abort.yes'),
          onClick: () => {
            this.abortCheckout();
            this.f7router.navigate({ name: 'cart', }, { reloadCurrent: true, });
          }
        }],
      }).open();
    },
    onBeforeIn() {
      this.isBillingAddressValid = false; // force reset on back
    },
    onAfterIn() {
      if (!this.CheckoutStore.orderId) {
        this.f7router.navigate({ name: 'cart', }, { reloadCurrent: true, });
        return;
      }
      if (this.CheckoutStore.orderConfirmed) {
        this.f7router.navigate({ name: 'checkout-payment-requests', }, { reloadCurrent: true, });
        return;
      }
      this.pullBillingAddress();
    },
  },
}
</script>

<style scoped>
.navbar .right .title {
  color: #aaa;
  font-weight: unset;
}
.wizard-steps {
  margin: 2rem 0;
}
.responsive > .button {
  margin-top: 0.5rem;
}
.responsive > .button-continue {
  margin-top: 2rem;
}
</style>
