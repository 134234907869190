import app from "./app";

const tools = {
    getFileExtension(fileName) {
        return fileName.match(/(?:\.([^.]+))?$/);
    },
    capitalize(s) {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    },
    formatCash(amount, locale, currency) {
        let formatter = new Intl.NumberFormat(locale, {
            style: 'currency',
            currency,
        });
        return formatter.format(amount);
    },
    roundToDecimalsPlaces(value, places) {
        let x = Math.pow(10, places);
        return Math.round((value + Number.EPSILON) * x) / x;
    }
};

export default tools;
