import io from "@/js/socket-io";
import apiErrorHandler from "@/js/api/error-handler";
import { useI18n } from "@/js/plugins/i18n";

const api = {

    pullShop(id) {
        return new Promise(async (resolve, reject) => {
            return this._getAccessToken().then(async accessToken => {
                console.debug(`API: shops.getShop(${id})`);
                io.socket.request({
                    method: 'GET',
                    url: `/api/v1/shops/${id}`,
                    headers: {
                        authorization: 'Bearer ' + accessToken,
                    }
                }, (body, JWR) => {
                    if (JWR.statusCode === 200) {
                        return resolve(body.shop);
                    }
                    let error = new Error();
                    error.flash = true;
                    error.message = useI18n().global.t('api.errors.default').toString();
                    console.log(JWR)
                    if (JWR.headers['X-Exit']) {
                        error.code = JWR.headers['X-Exit'];
                        switch (JWR.headers['X-Exit']) {

                        }
                    }
                    reject(error);
                });
            });
        })
            .catch(apiErrorHandler);
    },

};

export default api;
