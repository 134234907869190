<template>
  <f7-block-title medium>
    {{ $t('checkout.confirmation.billingAddress.title') }}
    <f7-link @click="onEditBillingAddress"><f7-icon material="edit"></f7-icon></f7-link>
  </f7-block-title>
  <f7-card
    class="checkout-confirmation-billing-address">
    <f7-card-content>
      <f7-block
        class="padding text-align-center"
        v-if="CheckoutStore.pullingOrderDetails">
        <f7-preloader></f7-preloader>
      </f7-block>
      <div
        v-if="!CheckoutStore.pullingOrderDetails && billingAddress"
        v-html="fBillingAddress">
      </div>
    </f7-card-content>
  </f7-card>
</template>
<script>
import {mapState} from 'vuex';

export default {
  props: {
    f7router: Object,
  },
  data() {
    return {

    }
  },
  computed: {
    ...mapState(['CheckoutStore']),
    billingAddress() {
      return this.CheckoutStore.orderConfirmation.billingAddress;
    },
    fBillingAddress() {
      return [
        [ this.billingAddress.firstname, this.billingAddress.lastname, this.billingAddress.company, ],
        [ this.billingAddress.nip, ],
        [ this.billingAddress.street, [ this.billingAddress.houseNumber, this.billingAddress.flatNumber, ].filter(v => !!v).join('/') ],
        [[ this.billingAddress.postCode, this.billingAddress.city ].join(', ')],
        [ this.$t('countries.' + this.billingAddress.country) ],
      ]
        .map(line => line.filter(v => !!v).join(' '))
        .filter(v => !!v)
        .join('<br />');
    },
  },
  methods: {
    onEditBillingAddress() {
      this.f7router.navigate({ name: 'checkout-address', });
    }
  },
}
</script>

<style scoped>
.block-title {
  display: flex;
  justify-content: space-between;
}
</style>
