<template>
  <f7-sheet
    class="payment-methods-sheet"
    style="height:auto; --f7-sheet-bg-color: #fff;"
    swipe-to-close
    swipe-to-step
    backdrop
  >
    <div class="sheet-modal-swipe-step">
      <div class="display-flex padding justify-content-space-between align-items-center">
        <div style="font-size: 18px"><b>{{ $t('checkout.paymentRequests.paymentMethods.title') }}:</b></div>
        <div style="font-size: 22px"><b>{{ amount }} <span class="currency">{{currencyCode}}</span></b></div>
      </div>
      <div class="padding-horizontal padding-bottom">
        <f7-button large fill @click="onPay">{{ $t('checkout.paymentRequests.paymentMethods.pay') }}</f7-button>
        <div class="margin-top text-align-center">{{ $t('checkout.paymentRequests.paymentMethods.moreDetails') }}</div>
      </div>
    </div>
    <f7-block-title medium class="margin-top">{{ $t('checkout.paymentRequests.paymentMethods.details.title') }}</f7-block-title>
    <f7-list no-hairlines>
      <f7-list-item
        v-for="orderItem of orderItems"
        :title="orderItem.name">
        <template #after>
          <span>
            {{ orderItem.price }}
            <span class="currency">{{ currencyCode }}</span>
            x {{ orderItem.qty }} {{ $t('szt') }} =
          </span>&nbsp;
          <b class="text-color-black">{{ orderItem.cost }} <span class="currency">{{ currencyCode }}</span></b>
        </template>
      </f7-list-item>
    </f7-list>
  </f7-sheet>
</template>

<script>
import {f7} from 'framework7-vue';
import {mapActions} from 'vuex';

export default {
  props: {
    paymentRequestId: {
      type: String,
      required: true,
    },
    amount: {
      type: Number,
      required: true,
    },
    orderItems: {
      type: Array,
      required: true,
    },
    shopId: {
      type: String,
      required: true,
    },
    currencyCode: {
      type: String,
      required: true,
    },
  },
  mounted() {
    f7.sheet.open('.payment-methods-sheet');
  },
  methods: {
    ...mapActions('CheckoutStore', ['pay']),
    onPay() {
      this.pay({ paymentRequestId: this.paymentRequestId, paymentMethod: 'payu' })
        .then((result) => {

          if (result && result.payByLink) {
            window.location = result.payByLink;
          }
        })
    }
  },
}
</script>

<style scoped>
span.currency {
  text-transform: uppercase;
}
</style>
