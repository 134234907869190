<template>
  <f7-block-title medium>{{ title }}</f7-block-title>
  <f7-card
    class="cart-section">
    <f7-card-content>
      <f7-block
        class="padding text-align-center"
        v-if="CartStore.loadingCartItems">
        <f7-preloader></f7-preloader>
      </f7-block>
      <f7-list
        class="cart-items"
        media-list
        no-hairlines
        v-if="CartStore.items.length">
        <ul>
          <cart-item
            v-for="item of CartStore.items"
            v-model:selected="item.selected"
            :key="item.id"
            :shop-id="item.shopId"
            :shop-name="item.shopName"
            :id="item.id"
            :name="item.name"
            :sku="item.sku"
            :qty="item.qty"
            :type="item.type"
            :currency-code="item.currencyCode"
            :price="item.price">
          </cart-item>
        </ul>
      </f7-list>
      <cart-empty
        v-if="!CartStore.loadingCartItems && !CartStore.items.length"></cart-empty>
    </f7-card-content>
  </f7-card>
</template>

<script>
import CartItem from '@/components/cart/items/item';
import CartEmpty from '@/components/cart/empty';
import {mapState} from 'vuex';

export default {
  components: {
    CartItem,
    CartEmpty
  },
  computed: {
    ...mapState(['CartStore']),
    title() {
      if (this.CartStore.items.length > 0) {
        return this.$t('cart.items.title.full', { items: this.CartStore.items.length, });
      } else {
        return this.$t('cart.items.title.empty');
      }
    },
  },
}
</script>
