import io from './socket-io';
import store from './store';
import cartsApi from '@/js/api/carts.api';
import ordersApi from '@/js/api/orders.api';
import paymentsApi from '@/js/api/payments.api';
import channelsApi from '@/js/api/channels.api';
import shopsApi from '@/js/api/shops.api';

const api = {

    _getAccessToken() {
        return new Promise((resolve) => {
            let idToken = store.getters['UserStore/getUserIdToken'];
            if (idToken) {
                resolve(idToken);
            } else {
                let waitUntilUserIsLoadedInterval = setInterval(() => {
                    let idToken = store.getters['UserStore/getUserIdToken'];
                    if (idToken) {
                        resolve(idToken);
                        clearInterval(waitUntilUserIsLoadedInterval);
                    }
                }, 1000);
            }
        });
    },

    ...channelsApi,
    ...cartsApi,
    ...ordersApi,
    ...paymentsApi,
    ...shopsApi,

}

export default api;
