<template>
  <f7-card no-shadow>
    <f7-button
      fill
      large
      @click="onConfirmOrder"
      :preloader="true"
      :loading="isLoading">{{ $t('checkout.buttons.confirm') }}</f7-button>
    <f7-button
      fill
      large
      color="red"
      @click="onAbort">{{ $t('checkout.buttons.abort') }}</f7-button>
  </f7-card>
</template>
<script>
import {mapActions, mapState} from 'vuex';
import {f7} from 'framework7-vue';

export default {
  props: {
    f7router: Object,
  },
  data() {
    return {
      isSubmitting: false,
    }
  },
  computed: {
    ...mapState(['CheckoutStore']),
    isLoading() {
      return this.CheckoutStore.pullingOrderDetails || this.isSubmitting;
    },
  },
  methods: {
    ...mapActions('CheckoutStore', ['confirmOrder', 'abortCheckout']),
    onConfirmOrder() {
      if (this.isSubmitting) {
        return;
      }
      this.isSubmitting = true;
      this.confirmOrder()
        .then(result => {
          if (result) {
            this.f7router.navigate({ name: 'checkout-payment-requests', });
          }
          this.isSubmitting = false;
        });
    },
    onAbort() {
      f7.dialog.create({
        title: this.$t('checkout.abort.title'),
        text: this.$t('checkout.abort.confirm'),
        buttons: [{
          text: this.$t('checkout.abort.no'),
        }, {
          text: this.$t('checkout.abort.yes'),
          onClick: () => {
            this.abortCheckout();
            this.f7router.navigate({ name: 'cart', });
          }
        }],
      }).open();
    },
  }
}
</script>

<style scoped>
.button {
  margin-top: 0.5rem;
}
</style>
