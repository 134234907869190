<template>
  <f7-block-title medium>{{ $t('cart.summary.title') }}</f7-block-title>
  <f7-card
    class="cart-section">
    <f7-card-content>
      <f7-list
        class="cart-summary"
        no-hairlines>
        <f7-list-item :title="$t('cart.summary.amount')" :after="fCartItemsTotalAmount"></f7-list-item>
        <f7-list-item :title="$t('cart.summary.shipping')" :after="$t('cart.summary.freeShipping')"></f7-list-item>
        <f7-list-item class="cart-summary-total" :title="$t('cart.summary.total')" :after="fCartItemsTotalAmount"></f7-list-item>
      </f7-list>
      <f7-button
        class="margin-top"
        fill
        large
        @click="onPlaceOrder"
        :disabled="!selectedItems.length"
        :preloader="true"
        :loading="CartStore.loadingPlaceOrder">{{ $t('cart.summary.placeOrderButton') }}</f7-button>
    </f7-card-content>
  </f7-card>
</template>
<script>
import {mapActions, mapState, mapGetters} from 'vuex';

export default {
  props: {
    f7router: Object,
  },
  computed: {
    ...mapState(['CartStore']),
    ...mapGetters('CartStore', ['selectedItems']),
    cartItemsTotalAmount() {
      return this.selectedItems.reduce((totalAmount, item) => (totalAmount + (item.price * item.qty)), 0);
    },
    fCartItemsTotalAmount() {
      return new Intl.NumberFormat('pl-PL', { style: 'currency', currency: 'PLN' }).format(this.cartItemsTotalAmount);
    },
  },
  methods: {
    ...mapActions('CartStore', ['placeOrder']),
    ...mapActions('CheckoutStore', ['resetCheckout']),
    onPlaceOrder() {
      this.resetCheckout();
      this.placeOrder()
        .then(result => {
          if (result) {
            this.f7router.navigate({ name: 'checkout-address', });
          }
        });
    }
  }
}
</script>

<style>
.cart-summary li .item-inner,
.cart-summary li .item-content {
  padding: 0;
}
.cart-summary-total .item-after,
.cart-summary-total .item-title {
  font-weight: bold;
  color: #000;
}
.cart-summary.list {
  margin: 0;
}
</style>
