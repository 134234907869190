import firebase from "firebase/app";
import store from '@/js/store';

const initialState = () => {
    return {
        user: null,
        error: null,
    };
};

const state = initialState();

const getters = {
    isUserAuth(state) {
        return !!state.user;
    },
    getUserIdToken(state) {
        return state.user && state.user.getIdToken ? state.user.getIdToken() : null;
    },
}

const actions = {
    signInAction({ commit }, payload) {
        return firebase
            .auth()
            .signInWithEmailAndPassword(payload.email, payload.password)
            .then(response => {
                commit("setUser", response.user);
            })
            .catch(error => {
                commit("setError", error.message);
            });
    },
    signInAnonymouslyAction({ commit }) {
        return firebase
            .auth()
            .signInAnonymously()
            .then(response => {
                commit("setUser", response.user);
            })
            .catch(error => {
                commit("setError", error.message);
            });
    },
    signOutAction({ commit }) {
        return firebase
            .auth()
            .signOut()
            .then(() => {
                commit("setUser", null);
            })
            .catch(error => {
                commit("setError", error.message);
            });
    },
    authAction({ commit }) {
        firebase.auth().onAuthStateChanged(async user => {
            if (user) {
                commit("setUser", user);
            } else {
                commit("setUser", null);
                await store.dispatch('UserStore/signInAnonymouslyAction');
            }
        });
    },
}

const mutations = {
    setUser(state, payload) {
        console.debug(payload)
        state.user = payload;
    },
    setError(state, payload) {
        state.error = payload;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
