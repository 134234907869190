import api from "@/js/api";
import store from "@/js/store";

const itemTemplate = () => {
    return {
        id: null,
        sku: null,
        name: null,
        qty: null,
        price: null,
        type: null,
        selected: null,
        shopName: null,
        shopId: null,
    }
}

const initialState = () => {
    return {
        items: [],
        loadingCartItems: false,
        loadingPlaceOrder: false,
        errorPlaceOrder: null,
        deletingCartItem: false,
        associating: false,
    };
};

const state = initialState();

const getters = {
    selectedItems(state) {
        let arr = state.items.filter(i => i.selected);
        if (arr.length === 0) {
            arr = state.items;
        }
        return arr;
    }
}

const actions = {
    pullCartItems({ commit, state }) {
        if (state.loadingCartItems) {
            return;
        }
        commit('setLoadingCartItems', true);
        api.pullCartItems()
            .then((result) => {
                if (result && result.items) {
                    commit('setCartItems', result.items);
                }
            })
            .finally(() => {
                commit('setLoadingCartItems', false);
            })
    },
    placeOrder({ commit, getters, state, }) {
        if (state.loadingPlaceOrder) {
            return Promise.resolve();
        }
        commit('setLoadingPlaceOrder', true);
        let items = getters.selectedItems;
        items = items.map(i => i.id);
        if (items.length > 100) {
            items.length = 100; // max 100 items per order
        }
        return api.placeOrder(items)
          .then((result) => {
              return store.dispatch('CheckoutStore/setOrder', result)
          })
          .then(() => true)
          .catch((e) => {
              commit('setErrorPlaceOrder', e);
              return false;
          })
          .finally(() => {
              commit('setLoadingPlaceOrder', false);
          });
    },
    deleteCartItem({ commit }, { itemId, }) {
        if (state.deletingCartItem) {
            return
        }
        commit('setDeletingCartItem', true);
        api.deleteCartItem(itemId)
          .then(() => {
              commit('deleteItemFromCart', itemId);
          })
          .finally(() => {
              commit('setDeletingCartItem', false);
          });
    },
    associateCartItems({ commit, }, { token, }) {
        if (state.associating) {
            return Promise.reject();
        }
        commit('setAssociating', true);
        return api.associateCartItems(token)
          .finally(() => {
              commit('setAssociating', false);
          });
    }
}

const mutations = {
    setCartItems(state, payload) {
        state.items = payload.map(item => {
            return Object.assign(itemTemplate(), item);
        });
    },
    setLoadingCartItems(state, payload) {
        state.loadingCartItems = payload;
    },
    setLoadingPlaceOrder(state, payload) {
        state.loadingPlaceOrder = payload;
    },
    setErrorPlaceOrder(state, payload) {
        state.errorPlaceOrder = payload;
    },
    setDeletingCartItem(state, payload) {
        state.deletingCartItem = payload;
    },
    setAssociating(state, payload) {
        state.associating = payload;
    },
    deleteItemFromCart(state, payload) {
        state.items = state.items.filter(i => i.id !== payload);
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
