<template>
  <form @submit.prevent="submit">
    <slot name="default"></slot>
  </form>
</template>

<script>
import _ from "lodash";

export default {
  emit: ['submit'],
  props: {
    result: {},
    resetState: {}
  },
  watch: {
    result(v) {
      if (v) {
        if (this.resetState) this.resetState();
      }
    },
  },
  methods: {
    validateComponent(component) {
      if (component.ref && component.ref.i && component.ref.i.refs[component.ref.r].vuelidate) {
        return component.ref.i.refs[component.ref.r].runValidation();
      } else {
        return true;
      }
    },
    resetComponent(component) {
      if (component.ref && component.ref.i && component.ref.i.refs[component.ref.r].vuelidate) {
        let $v = component.ref.i.refs[component.ref.r].vuelidate;
        $v.$reset();
      }
    },
    walkComponentsRecursiveAndValidate(children) {
      let result = true;
      if (_.isArray(children)) {
        for (let child of children) {
          if (!this.validateComponent(child)) {
            result = false;
          }
          if (!this.walkComponentsRecursiveAndValidate(child.children)) {
            result = false;
          }
        }
      } else if (_.isObject(children) && children.default) {
        if (!this.walkComponentsRecursiveAndValidate(children.default())) {
          result = false;
        }
      }
      return result;
    },
    walkComponentsRecursiveAndReset(children) {
      if (_.isArray(children)) {
        for (let child of children) {
          this.resetComponent(child);
          this.walkComponentsRecursiveAndReset(child.children);
        }
      } else if (_.isObject(children) && children.default) {
        this.walkComponentsRecursiveAndReset(children.default());
      }
    },
    resetRecursive() {
      this.walkComponentsRecursiveAndReset(this.$slots.default());
    },
    isFormValid() {
      return this.walkComponentsRecursiveAndValidate(this.$slots.default());
    },
    submit() {
      let valid = this.isFormValid();
      this.$emit('submit', { valid: valid, });
      if (valid) {
        this.resetRecursive();
      }
    },
  },
}
</script>
