const socketIo = require('sails.io.js')( require('socket.io-client') );
import store from './store';

socketIo.sails.url = process.env.API;
socketIo.sails.reconnection = true;
socketIo.socket.useCORSRouteToGetCookie = false;
socketIo.socket.query = 'nosession=true';

const io = {
    socket: socketIo.socket,
};

io.socket.on('disconnect', (reason) => {
    if (reason === 'io server disconnect') {
        io.socket.connect();
    }
    // disconnectedToast = app.$f7.toast.create({
    //     text: 'Utracono połączenie z serwerem. Łączenie...',
    // }).open();
});

io.socket.on('connect', () => {
    store.dispatch('SocketsStore/handleSocketConnect').catch(console.error);
});

io.socket.on('pull_cart', (data) => {
    store.dispatch('CartStore/pullCartItems').catch(console.error);
});

io.socket.on('delete_cart_items', (data) => {
    data.cartItems.map(item => store.commit('CartStore/deleteItemFromCart', item.id));
});

export default io;
